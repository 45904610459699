import { createRouter, createWebHistory } from 'vue-router'
import { loadLayoutMiddleware } from "@/router/middleware/loadLayoutMiddleware";

const routes = [
  {
    path: '/users/all',
    name: 'Users all',
    component: () => import('../pages/Admin/Users/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/users/partners',
    name: 'Users partners',
    component: () => import('../pages/Admin/Users/partners.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/users/warehouse',
    name: 'Users warehouse',
    component: () => import('../pages/Admin/Users/warehouse.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/users/admin',
    name: 'Users admin',
    component: () => import('../pages/Admin/Users/admin.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/users/user/:id',
    name: 'Users id',
    component: () => import('../pages/Admin/Users/[id].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('../pages/Admin/Main/Banners/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/banners/create',
    name: 'Banners_create',
    component: () => import('../pages/Admin/Main/Banners/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/banners/update/:id',
    name: 'Banners_update',
    component: () => import('../pages/Admin/Main/Banners/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/products',
    name: 'Products',
    component: () => import('../pages/Admin/Products/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/products/create',
    name: 'Products create',
    component: () => import('../pages/Admin/Products/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/products/update/:id',
    name: 'Products update',
    component: () => import('../pages/Admin/Products/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/products/show/:id',
    name: 'Products show',
    component: () => import('../pages/Admin/Products/[show].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/categories',
    name: 'Categories',
    component: () => import('../pages/Admin/Categories/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/categories/create',
    name: 'Categories_create',
    component: () => import('../pages/Admin/Categories/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/catalog/categories/edit/:id',
    name: 'Categories_update',
    component: () => import('../pages/Admin/Categories/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/categories',
    name: "Blog categories",
    component: () => import('../pages/Admin/Blog/categories.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/categories/create',
    name: "Blog categories create",
    component: () => import('../pages/Admin/Blog/categories/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/categories/update/:id',
    name: "Blog categories update",
    component: () => import('../pages/Admin/Blog/categories/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/posts',
    name: "Blog posts",
    component: () => import('../pages/Admin/Blog/posts.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/posts/create',
    name: "Blog posts create",
    component: () => import('../pages/Admin/Blog/posts/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/blog/posts/update/:id',
    name: "Blog posts update",
    component: () => import('../pages/Admin/Blog/posts/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/promocodes',
    name: "Promocodes",
    component: () => import('../pages/Admin/Promocodes/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/promocodes/create',
    name: "Promocode_create",
    component: () => import('../pages/Admin/Promocodes/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/clients/heading',
    name: "Clients_heading",
    component: () => import('../pages/Admin/Clients/Heading.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/clients/content',
    name: "Clients_content",
    component: () => import('../pages/Admin/Clients/Content.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/orders',
    name: "Orders",
    component: () => import('../pages/Admin/Orders/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/orders/:id',
    name: "Order id",
    component: () => import('../pages/Admin/Orders/id.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages',
    name: "Pages",
    component: () => import('../pages/Admin/Pages/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages/customers',
    name: "Pages customers",
    component: () => import('../pages/Admin/Pages/components/Customers/Content/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages/customers/create',
    name: "Pages customers create",
    component: () => import('../pages/Admin/Pages/components/Customers/Content/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages/customers/update/:id',
    name: "Pages customers update",
    component: () => import('../pages/Admin/Pages/components/Customers/Content/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages/:pages_name/block/create',
    name: "Pages block create",
    component: () => import('../pages/Admin/Pages/Block/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/pages/:pages_name/block/update/:id',
    name: "Pages block update",
    component: () => import('../pages/Admin/Pages/Block/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/about/sliders/:id',
    name: "Pages block slider",
    component: () => import('../pages/Admin/Pages/components/About/Sliders/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/advantages/:id',
    name: "Pages block partners advng",
    component: () => import('../pages/Admin/Pages/components/Partners/Advantages/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/advantages/3/create',
    name: "Pages block partners advng create",
    component: () => import('../pages/Admin/Pages/components/Partners/Advantages/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/advantages/3/update/:id',
    name: "Pages block partners advng update",
    component: () => import('../pages/Admin/Pages/components/Partners/Advantages/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/scope/:id',
    name: "Pages block partners scope",
    component: () => import('../pages/Admin/Pages/components/Partners/Scope/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/scope/8/create',
    name: "Pages block partners scope create",
    component: () => import('../pages/Admin/Pages/components/Partners/Scope/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/partners/scope/8/update/:id',
    name: "Pages block partners scope update",
    component: () => import('../pages/Admin/Pages/components/Partners/Scope/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/:id',
    name: "Pages block partners schools",
    component: () => import('../pages/Admin/Pages/components/School/Advantages/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/16/create',
    name: "Pages block partners schools create",
    component: () => import('../pages/Admin/Pages/components/School/Advantages/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/16/update/:id',
    name: "Pages block partners schools update",
    component: () => import('../pages/Admin/Pages/components/School/Advantages/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/18',
    name: "Pages block partners schools course",
    component: () => import('../pages/Admin/Pages/components/School/Course/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/18/create',
    name: "Pages block partners schools program create",
    component: () => import('../pages/Admin/Pages/components/School/Course/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/18/update/:id',
    name: "Pages block partners schools program update",
    component: () => import('../pages/Admin/Pages/components/School/Course/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/17',
    name: "Pages block partners schools team",
    component: () => import('../pages/Admin/Pages/components/School/Team/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/17/create',
    name: "Pages block partners schools Team create",
    component: () => import('../pages/Admin/Pages/components/School/Team/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/17/update/:id',
    name: "Pages block partners schools Team update",
    component: () => import('../pages/Admin/Pages/components/School/Team/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/15',
    name: "Pages block partners ceo team",
    component: () => import('../pages/Admin/Pages/components/School/CEO/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/15/create',
    name: "Pages block partners ceo Team create",
    component: () => import('../pages/Admin/Pages/components/School/CEO/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/schools/15/update/:id',
    name: "Pages block partners ceo Team update",
    component: () => import('../pages/Admin/Pages/components/School/CEO/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/about/sliders/12/create',
    name: "Pages block slider create",
    component: () => import('../pages/Admin/Pages/components/About/Sliders/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/about/sliders/12/update/:id',
    name: "Pages block slider update",
    component: () => import('../pages/Admin/Pages/components/About/Sliders/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/settings',
    name: "Settings store",
    component: () => import('../pages/Admin/Settings/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/account',
    name: "Account store",
    component: () => import('../pages/Admin/Settings/account.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/payments/create',
    name: "Payments create",
    component: () => import('../pages/Admin/Settings/Payments/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/payments/update/:id',
    name: "Payments update",
    component: () => import('../pages/Admin/Settings/Payments/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/delivery/create',
    name: "delivery create",
    component: () => import('../pages/Admin/Settings/Delivery/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/delivery/update/:id',
    name: "delivery update",
    component: () => import('../pages/Admin/Settings/Delivery/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/warehouse',
    name: "warehouses",
    component: () => import('../pages/Admin/Warehouses/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/warehouse/:id',
    name: "warehouse id",
    component: () => import('../pages/Admin/Warehouses/[id].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/warehouse-dashboard',
    name: "warehouse_dashboard",
    component: () => import('../pages/Admin/WarehouseDashboard/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/warehouse-dashboard/:id',
    name: "warehouse_dashboard_id",
    component: () => import('../pages/Admin/WarehouseDashboard/[id].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/treegraph',
    name: 'Treegraph',
    component: () => import('../pages/Admin/Treegraph/index.vue'),
    meta: {
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory',
    name: "directory",
    component: () => import('../pages/Admin/Directory/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory/country/create',
    name: "directory country create",
    component: () => import('../pages/Admin/Directory/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory/country/update/:id',
    name: "directory country update",
    component: () => import('../pages/Admin/Directory/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory/cities/:id',
    name: "directory cities",
    component: () => import('../pages/Admin/Directory/cities/index.vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory/cities/:id/create',
    name: "directory cities create",
    component: () => import('../pages/Admin/Directory/cities/[create].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/directory/cities/:id/update/:city_id',
    name: "directory cities update",
    component: () => import('../pages/Admin/Directory/cities/[update].vue'),
    meta: { 
      requiresAuth: true,
      layout: 'AppLayoutAdmin'
    }
  },
  {
    path: '/',
    name: 'Login',
    component: () => import('../pages/Auth/index.vue'),
    meta: {
      requiresAuth: false,
      layout: 'AppLayoutAuth'
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../pages/404/index.vue'),
    meta: {
      layout: 'AppLayoutBasic'
    }
  },
]

const token = sessionStorage.getItem("authAdminToken")

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
})

router.beforeEach(loadLayoutMiddleware)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
